.background {
    background-image: url('./../../assets/banner.jpg');
    background-size: "cover";
    background-repeat: "no-repeat";
    background-position: "center";
    height: "500px"
}

.header h1 {
    letter-spacing: 4px;
}

.header h4 {
    letter-spacing: 1px;
}

.header h1 span {
    color: rgb(189, 179, 179); 
}